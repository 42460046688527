@import url("fontawesome-all.min.css");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,900,300italic");

/*
	Dopetrope by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	-webkit-text-size-adjust: none;
}

mark {
	background-color: transparent;
	color: inherit;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input, select, textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}

/* Basic */

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	body {
		background: #252122;
		background-image: url("images/bg.png");
	}

		body.is-preload *, body.is-preload *:before, body.is-preload *:after {
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
		}

	body, input, textarea, select {
		font-family: 'Source Sans Pro';
		font-weight: 300;
		color: #5d5d5d;
		font-size: 14pt;
		line-height: 1.75em;
	}

	strong, b {
		color: #252122;
		font-weight: 400;
	}

	i, em {
		font-style: italic;
		text-align: right;
	}

	sub {
		position: relative;
		top: 0.5em;
		font-size: 0.8em;
	}

	sup {
		position: relative;
		top: -0.5em;
		font-size: 0.8em;
	}

	blockquote {
		border-left: solid 0.75em #dddddd;
		padding: 1em 0 1em 1.5em;
		font-style: italic;
	}

	h1, h2, h3, h4, h5, h6 {
		color: #252122;
		font-weight: 700;
	}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
		color: inherit;
		text-decoration: none;
	}

	h2 {
		font-size: 1.5em;
	}

	h3 {
		font-size: 1.35em;
	}

	a {
		color: #d52349;
		text-decoration: underline;
	}

		a:hover {
			text-decoration: none;
		}

	br.clear {
		clear: both;
	}

	hr {
		border: 0;
		border-top: solid 1px #dddddd;
		height: 1px;
		margin: 2em 0 2em 0;
	}

	p, ul, ol, dl, table {
		margin-bottom: 2em;
	}

/* Container */

	.container {
		margin: 0 auto;
		max-width: 100%;
		width: 70em;
	}

		@media screen and (max-width: 1680px) {

			.container {
				width: 70em;
			}

		}

		@media screen and (max-width: 1280px) {

			.container {
				width: calc(100% - 60px);
			}

		}

		@media screen and (max-width: 980px) {

			.container {
				width: calc(100% - 80px);
			}

		}

		@media screen and (max-width: 736px) {

			.container {
				width: calc(100% - 40px);
			}

		}

/* Row */

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
	}

		.row > * {
			box-sizing: border-box;
		}

		.row.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		.row.aln-left {
			justify-content: flex-start;
		}

		.row.aln-center {
			justify-content: center;
		}

		.row.aln-right {
			justify-content: flex-end;
		}

		.row.aln-top {
			align-items: flex-start;
		}

		.row.aln-middle {
			align-items: center;
		}

		.row.aln-bottom {
			align-items: flex-end;
		}

		.row > .imp {
			order: -1;
		}

		.row > .col-1 {
			width: 8.33333%;
		}

		.row > .off-1 {
			margin-left: 8.33333%;
		}

		.row > .col-2 {
			width: 16.66667%;
		}

		.row > .off-2 {
			margin-left: 16.66667%;
		}

		.row > .col-3 {
			width: 25%;
		}

		.row > .off-3 {
			margin-left: 25%;
		}

		.row > .col-4 {
			width: 33.33333%;
		}

		.row > .off-4 {
			margin-left: 33.33333%;
		}

		.row > .col-5 {
			width: 41.66667%;
		}

		.row > .off-5 {
			margin-left: 41.66667%;
		}

		.row > .col-6 {
			width: 50%;
		}

		.row > .off-6 {
			margin-left: 50%;
		}

		.row > .col-7 {
			width: 58.33333%;
		}

		.row > .off-7 {
			margin-left: 58.33333%;
		}

		.row > .col-8 {
			width: 66.66667%;
		}

		.row > .off-8 {
			margin-left: 66.66667%;
		}

		.row > .col-9 {
			width: 75%;
		}

		.row > .off-9 {
			margin-left: 75%;
		}

		.row > .col-10 {
			width: 83.33333%;
		}

		.row > .off-10 {
			margin-left: 83.33333%;
		}

		.row > .col-11 {
			width: 91.66667%;
		}

		.row > .off-11 {
			margin-left: 91.66667%;
		}

		.row > .col-12 {
			width: 100%;
		}

		.row > .off-12 {
			margin-left: 100%;
		}

		.row.gtr-0 {
			margin-top: 0px;
			margin-left: 0px;
		}

			.row.gtr-0 > * {
				padding: 0px 0 0 0px;
			}

			.row.gtr-0.gtr-uniform {
				margin-top: 0px;
			}

				.row.gtr-0.gtr-uniform > * {
					padding-top: 0px;
				}

		.row.gtr-25 {
			margin-top: -12.5px;
			margin-left: -12.5px;
		}

			.row.gtr-25 > * {
				padding: 12.5px 0 0 12.5px;
			}

			.row.gtr-25.gtr-uniform {
				margin-top: -12.5px;
			}

				.row.gtr-25.gtr-uniform > * {
					padding-top: 12.5px;
				}

		.row.gtr-50 {
			margin-top: -25px;
			margin-left: -25px;
		}

			.row.gtr-50 > * {
				padding: 25px 0 0 25px;
			}

			.row.gtr-50.gtr-uniform {
				margin-top: -25px;
			}

				.row.gtr-50.gtr-uniform > * {
					padding-top: 25px;
				}

		.row {
			margin-top: -50px;
			margin-left: -50px;
		}

			.row > * {
				padding: 50px 0 0 50px;
			}

			.row.gtr-uniform {
				margin-top: -50px;
			}

				.row.gtr-uniform > * {
					padding-top: 50px;
				}

		.row.gtr-150 {
			margin-top: -75px;
			margin-left: -75px;
		}

			.row.gtr-150 > * {
				padding: 75px 0 0 75px;
			}

			.row.gtr-150.gtr-uniform {
				margin-top: -75px;
			}

				.row.gtr-150.gtr-uniform > * {
					padding-top: 75px;
				}

		.row.gtr-200 {
			margin-top: -100px;
			margin-left: -100px;
		}

			.row.gtr-200 > * {
				padding: 100px 0 0 100px;
			}

			.row.gtr-200.gtr-uniform {
				margin-top: -100px;
			}

				.row.gtr-200.gtr-uniform > * {
					padding-top: 100px;
				}

		@media screen and (max-width: 1680px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-xlarge {
					order: -1;
				}

				.row > .col-1-xlarge {
					width: 8.33333%;
				}

				.row > .off-1-xlarge {
					margin-left: 8.33333%;
				}

				.row > .col-2-xlarge {
					width: 16.66667%;
				}

				.row > .off-2-xlarge {
					margin-left: 16.66667%;
				}

				.row > .col-3-xlarge {
					width: 25%;
				}

				.row > .off-3-xlarge {
					margin-left: 25%;
				}

				.row > .col-4-xlarge {
					width: 33.33333%;
				}

				.row > .off-4-xlarge {
					margin-left: 33.33333%;
				}

				.row > .col-5-xlarge {
					width: 41.66667%;
				}

				.row > .off-5-xlarge {
					margin-left: 41.66667%;
				}

				.row > .col-6-xlarge {
					width: 50%;
				}

				.row > .off-6-xlarge {
					margin-left: 50%;
				}

				.row > .col-7-xlarge {
					width: 58.33333%;
				}

				.row > .off-7-xlarge {
					margin-left: 58.33333%;
				}

				.row > .col-8-xlarge {
					width: 66.66667%;
				}

				.row > .off-8-xlarge {
					margin-left: 66.66667%;
				}

				.row > .col-9-xlarge {
					width: 75%;
				}

				.row > .off-9-xlarge {
					margin-left: 75%;
				}

				.row > .col-10-xlarge {
					width: 83.33333%;
				}

				.row > .off-10-xlarge {
					margin-left: 83.33333%;
				}

				.row > .col-11-xlarge {
					width: 91.66667%;
				}

				.row > .off-11-xlarge {
					margin-left: 91.66667%;
				}

				.row > .col-12-xlarge {
					width: 100%;
				}

				.row > .off-12-xlarge {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -12.5px;
					margin-left: -12.5px;
				}

					.row.gtr-25 > * {
						padding: 12.5px 0 0 12.5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -12.5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 12.5px;
						}

				.row.gtr-50 {
					margin-top: -25px;
					margin-left: -25px;
				}

					.row.gtr-50 > * {
						padding: 25px 0 0 25px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -25px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 25px;
						}

				.row {
					margin-top: -50px;
					margin-left: -50px;
				}

					.row > * {
						padding: 50px 0 0 50px;
					}

					.row.gtr-uniform {
						margin-top: -50px;
					}

						.row.gtr-uniform > * {
							padding-top: 50px;
						}

				.row.gtr-150 {
					margin-top: -75px;
					margin-left: -75px;
				}

					.row.gtr-150 > * {
						padding: 75px 0 0 75px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -75px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 75px;
						}

				.row.gtr-200 {
					margin-top: -100px;
					margin-left: -100px;
				}

					.row.gtr-200 > * {
						padding: 100px 0 0 100px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -100px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 100px;
						}

		}

		@media screen and (max-width: 1280px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-large {
					order: -1;
				}

				.row > .col-1-large {
					width: 8.33333%;
				}

				.row > .off-1-large {
					margin-left: 8.33333%;
				}

				.row > .col-2-large {
					width: 16.66667%;
				}

				.row > .off-2-large {
					margin-left: 16.66667%;
				}

				.row > .col-3-large {
					width: 25%;
				}

				.row > .off-3-large {
					margin-left: 25%;
				}

				.row > .col-4-large {
					width: 33.33333%;
				}

				.row > .off-4-large {
					margin-left: 33.33333%;
				}

				.row > .col-5-large {
					width: 41.66667%;
				}

				.row > .off-5-large {
					margin-left: 41.66667%;
				}

				.row > .col-6-large {
					width: 50%;
				}

				.row > .off-6-large {
					margin-left: 50%;
				}

				.row > .col-7-large {
					width: 58.33333%;
				}

				.row > .off-7-large {
					margin-left: 58.33333%;
				}

				.row > .col-8-large {
					width: 66.66667%;
				}

				.row > .off-8-large {
					margin-left: 66.66667%;
				}

				.row > .col-9-large {
					width: 75%;
				}

				.row > .off-9-large {
					margin-left: 75%;
				}

				.row > .col-10-large {
					width: 83.33333%;
				}

				.row > .off-10-large {
					margin-left: 83.33333%;
				}

				.row > .col-11-large {
					width: 91.66667%;
				}

				.row > .off-11-large {
					margin-left: 91.66667%;
				}

				.row > .col-12-large {
					width: 100%;
				}

				.row > .off-12-large {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -7.5px;
					margin-left: -7.5px;
				}

					.row.gtr-25 > * {
						padding: 7.5px 0 0 7.5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -7.5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 7.5px;
						}

				.row.gtr-50 {
					margin-top: -15px;
					margin-left: -15px;
				}

					.row.gtr-50 > * {
						padding: 15px 0 0 15px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -15px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 15px;
						}

				.row {
					margin-top: -30px;
					margin-left: -30px;
				}

					.row > * {
						padding: 30px 0 0 30px;
					}

					.row.gtr-uniform {
						margin-top: -30px;
					}

						.row.gtr-uniform > * {
							padding-top: 30px;
						}

				.row.gtr-150 {
					margin-top: -45px;
					margin-left: -45px;
				}

					.row.gtr-150 > * {
						padding: 45px 0 0 45px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -45px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 45px;
						}

				.row.gtr-200 {
					margin-top: -60px;
					margin-left: -60px;
				}

					.row.gtr-200 > * {
						padding: 60px 0 0 60px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -60px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 60px;
						}

		}

		@media screen and (max-width: 980px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-medium {
					order: -1;
				}

				.row > .col-1-medium {
					width: 8.33333%;
				}

				.row > .off-1-medium {
					margin-left: 8.33333%;
				}

				.row > .col-2-medium {
					width: 16.66667%;
				}

				.row > .off-2-medium {
					margin-left: 16.66667%;
				}

				.row > .col-3-medium {
					width: 25%;
				}

				.row > .off-3-medium {
					margin-left: 25%;
				}

				.row > .col-4-medium {
					width: 33.33333%;
				}

				.row > .off-4-medium {
					margin-left: 33.33333%;
				}

				.row > .col-5-medium {
					width: 41.66667%;
				}

				.row > .off-5-medium {
					margin-left: 41.66667%;
				}

				.row > .col-6-medium {
					width: 50%;
				}

				.row > .off-6-medium {
					margin-left: 50%;
				}

				.row > .col-7-medium {
					width: 58.33333%;
				}

				.row > .off-7-medium {
					margin-left: 58.33333%;
				}

				.row > .col-8-medium {
					width: 66.66667%;
				}

				.row > .off-8-medium {
					margin-left: 66.66667%;
				}

				.row > .col-9-medium {
					width: 75%;
				}

				.row > .off-9-medium {
					margin-left: 75%;
				}

				.row > .col-10-medium {
					width: 83.33333%;
				}

				.row > .off-10-medium {
					margin-left: 83.33333%;
				}

				.row > .col-11-medium {
					width: 91.66667%;
				}

				.row > .off-11-medium {
					margin-left: 91.66667%;
				}

				.row > .col-12-medium {
					width: 100%;
				}

				.row > .off-12-medium {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-25 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 10px;
						}

				.row.gtr-50 {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row.gtr-50 > * {
						padding: 20px 0 0 20px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 20px;
						}

				.row {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-uniform > * {
							padding-top: 40px;
						}

				.row.gtr-150 {
					margin-top: -60px;
					margin-left: -60px;
				}

					.row.gtr-150 > * {
						padding: 60px 0 0 60px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -60px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 60px;
						}

				.row.gtr-200 {
					margin-top: -80px;
					margin-left: -80px;
				}

					.row.gtr-200 > * {
						padding: 80px 0 0 80px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -80px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 80px;
						}

		}

		@media screen and (max-width: 736px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-small {
					order: -1;
				}

				.row > .col-1-small {
					width: 8.33333%;
				}

				.row > .off-1-small {
					margin-left: 8.33333%;
				}

				.row > .col-2-small {
					width: 16.66667%;
				}

				.row > .off-2-small {
					margin-left: 16.66667%;
				}

				.row > .col-3-small {
					width: 25%;
				}

				.row > .off-3-small {
					margin-left: 25%;
				}

				.row > .col-4-small {
					width: 33.33333%;
				}

				.row > .off-4-small {
					margin-left: 33.33333%;
				}

				.row > .col-5-small {
					width: 41.66667%;
				}

				.row > .off-5-small {
					margin-left: 41.66667%;
				}

				.row > .col-6-small {
					width: 50%;
				}

				.row > .off-6-small {
					margin-left: 50%;
				}

				.row > .col-7-small {
					width: 58.33333%;
				}

				.row > .off-7-small {
					margin-left: 58.33333%;
				}

				.row > .col-8-small {
					width: 66.66667%;
				}

				.row > .off-8-small {
					margin-left: 66.66667%;
				}

				.row > .col-9-small {
					width: 75%;
				}

				.row > .off-9-small {
					margin-left: 75%;
				}

				.row > .col-10-small {
					width: 83.33333%;
				}

				.row > .off-10-small {
					margin-left: 83.33333%;
				}

				.row > .col-11-small {
					width: 91.66667%;
				}

				.row > .off-11-small {
					margin-left: 91.66667%;
				}

				.row > .col-12-small {
					width: 100%;
				}

				.row > .off-12-small {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -5px;
					margin-left: -5px;
				}

					.row.gtr-25 > * {
						padding: 5px 0 0 5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 5px;
						}

				.row.gtr-50 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-50 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 10px;
						}

				.row {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row > * {
						padding: 20px 0 0 20px;
					}

					.row.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-uniform > * {
							padding-top: 20px;
						}

				.row.gtr-150 {
					margin-top: -30px;
					margin-left: -30px;
				}

					.row.gtr-150 > * {
						padding: 30px 0 0 30px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -30px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 30px;
						}

				.row.gtr-200 {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row.gtr-200 > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 40px;
						}

		}

/* Form */

	form label {
		display: block;
		color: #252122;
		font-weight: 400;
		margin: 0 0 0.5em 0;
	}

	form input[type=text],
	form input[type=email],
	form input[type=password],
	form select,
	form textarea {
		-webkit-appearance: none;
		display: block;
		border: 0;
		padding: 0.75em 1em;
		font-size: 1em;
		border-radius: 5px;
		border: solid 1px #dddddd;
		background: #fff;
		width: 100%;
	}

		form input[type=text]:focus,
		form input[type=email]:focus,
		form input[type=password]:focus,
		form select:focus,
		form textarea:focus {
			box-shadow: inset 0px 0px 1px 1px #43bff0;
		}

	form textarea {
		height: 15em;
	}

	form ::-webkit-input-placeholder {
		color: #555 !important;
	}

	form :-moz-placeholder {
		color: #555 !important;
	}

	form ::-moz-placeholder {
		color: #555 !important;
	}

	form :-ms-input-placeholder {
		color: #555 !important;
	}

	form ::-moz-focus-inner {
		border: 0;
	}

/* Tables */

	table {
		width: 100%;
	}

		table tr {
			border-top: solid 1px #dddddd;
		}

			table tr:first-child {
				border-top: 0;
			}

		table td {
			padding: 0.5em 1em 0.5em 1em;
		}

		table th {
			text-align: left;
			padding: 0.5em 1em 0.5em 1em;
			font-weight: 700;
			margin: 0 0 1em 0;
		}

		table thead {
			border-bottom: solid 2px #dddddd;
		}

/* Section/Article */

	section, article {
		margin-bottom: 2.5em;
	}

		section > :last-child, section:last-child, article > :last-child, article:last-child {
			margin-bottom: 0;
		}

	header {
		margin: 0 0 1.5em 0;
	}

		header > p {
			display: block;
			font-style: italic;
			margin: 0.5em 0 0 0;
			padding-bottom: 0.5em;
		}

		header.major {
			position: relative;
			text-align: center;
			border-top: solid 1px #ccc;
			top: 1em;
			margin: 0 0 3em 0;
		}

			header.major h2 {
				background: #fff;
				position: relative;
				top: -0.65em;
				display: inline;
				margin: 0;
				padding: 0 1em 0 1em;
			}

	footer {
		margin: 2.25em 0 0 0;
	}

	.footer {
		background: #252122;
	}

/* Box */

	.box {
		background: #ffffff;
		border-bottom: solid 1px #ccc;
		padding: 2.75em 1.75em 2.75em 1.75em;
	}

		.box .image.featured {
			position: relative;
			width: auto;
			left: 1.75em;
			top: 1.75em;
			margin: -4.5em 0 4.25em -3.5em;
		}

		.box.post header {
			padding-top: 1em;
			margin: 0 0 2em 0;
		}

		.box.post h2 {
			font-size: 2.5em;
			letter-spacing: -0.015em;
		}

		.box.post header > p {
			margin-top: 1.25em;
			font-size: 1.25em;
		}

		.box footer ul.actions {
			margin-bottom: 0;
		}

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		-webkit-appearance: none;
		display: block;
		text-decoration: none;
		cursor: pointer;
		border: 0;
		border-radius: 5px;
		background: #d52349;
		color: #fff !important;
		font-weight: 700;
		outline: 0;
		font-size: 1em;
		padding: 0.65em 1.5em 0.65em 1.5em;
		margin: 2px;
		text-align: center;
		-moz-transition: background-color .25s ease-in-out;
		-webkit-transition: background-color .25s ease-in-out;
		-ms-transition: background-color .25s ease-in-out;
		transition: background-color .25s ease-in-out;
	}

		input[type="button"]:hover,
		input[type="submit"]:hover,
		input[type="reset"]:hover,
		button:hover,
		.button:hover {
			background: #e53359;
		}

		input[type="button"]:active,
		input[type="submit"]:active,
		input[type="reset"]:active,
		button:active,
		.button:active {
			background: #c51349;
		}

		input[type="button"].alt,
		input[type="submit"].alt,
		input[type="reset"].alt,
		button.alt,
		.button.alt {
			background: #252122;
		}

			input[type="button"].alt:hover,
			input[type="submit"].alt:hover,
			input[type="reset"].alt:hover,
			button.alt:hover,
			.button.alt:hover {
				background: #353132;
			}

			input[type="button"].alt:active,
			input[type="submit"].alt:active,
			input[type="reset"].alt:active,
			button.alt:active,
			.button.alt:active {
				background: #151112;
			}

		input[type="button"].icon:before,
		input[type="submit"].icon:before,
		input[type="reset"].icon:before,
		button.icon:before,
		.button.icon:before {
			opacity: 0.35;
			position: relative;
			top: 0.05em;
			margin-right: 0.75em;
		}

		input[type="button"].large,
		input[type="submit"].large,
		input[type="reset"].large,
		button.large,
		.button.large {
			font-size: 1.5em;
			padding: 0.75em 1.5em 0.75em 1.5em;
		}

/* Image */

	.full-logo {
		width: auto;
		display: inline-block;
		height: 20rem;
		align-content: center;
		padding: -100px;
		margin: -100px;
	}	

	.image {
		display: inline-block;
		outline: 0;
	}

		.image img {
			display: block;
			width: 100%;
		}

		.image.centered {
			display: block;
			margin: 0 0 2em 0;
		}

			.image.centered img {
				margin: 0 auto;
				width: auto;
			}

		.image.featured {
			display: block;
			width: 100%;
			margin: 2em 2em 2em 0;
		}

		.image.left {
			float: left;
			margin: 0 2em 2em 0;
		}

/* List */

	ul {
		list-style: disc;
		padding-left: 1em;
	}

		ul li {
			padding-left: 0.5em;
		}

	ol {
		list-style: decimal;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

/* Actions */

	ul.actions {
		list-style: none;
		padding-left: 0;
	}

		ul.actions li {
			display: inline-block;
			margin-left: 1em;
			padding-left: 0;
		}

			ul.actions li:first-child {
				margin-left: 0;
			}

/* Links */

	ul.links {
		list-style: none;
		padding-left: 0;
	}

		ul.links li {
			display: inline-block;
			border-left: solid 1px rgba(255, 255, 255, 0.05);
			padding: 0 0 0 1em;
			margin: 0 0 0 1em;
		}

			ul.links li:first-child {
				border-left: 0;
				padding-left: 0;
				margin-left: 0;
			}

/* Contact */

	ul.contact {
		list-style: none;
		padding-left: 0;
	}

		ul.contact li {
			position: relative;
			border-top: solid 1px #ddd;
			padding: 1.3em 0 1.3em 7em;
		}

			ul.contact li h3 {
				position: absolute;
				left: 0;
				top: 1.3em;
				font-size: 1.1em;
			}

			ul.contact li p {
				margin: 0;
			}

			ul.contact li:first-child {
				padding-top: 0;
				border-top: 0;
			}

				ul.contact li:first-child h3 {
					top: 0;
				}

/* Dates */

	ul.dates {
		list-style: none;
		padding-left: 0;
	}

		ul.dates li {
			position: relative;
			border-top: solid 1px #ddd;
			padding: 1.3em 0 1.3em 6.75em;
		}

		ul.dates .date {
			display: block;
			position: absolute;
			left: 0;
			top: 1.3em;
			background-color: #d52349;
			height: 3.5em;
			text-align: center;
			color: #fff;
			line-height: 1em;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			padding: 0.5em 0.75em 0 1em;
		}

			ul.dates .date:after {
				content: '';
				position: absolute;
				bottom: 0;
				right: -1.2em;
				border-left: solid 1.25em #d52349;
				border-top: solid 1.8em transparent;
				border-bottom: solid 1.8em transparent;
			}

			ul.dates .date strong {
				display: block;
				font-size: 1.75em;
				padding-top: 0.15em;
			}

		ul.dates h3 {
			font-size: 1.1em;
		}

		ul.dates p {
			margin: 0;
		}

		ul.dates li:first-child {
			border-top: 0;
			padding-top: 0;
		}

			ul.dates li:first-child .date {
				top: 0;
			}

/* Divided */

	ul.divided {
		list-style: none;
		padding-left: 0;
	}

		ul.divided li {
			border-top: solid 1px #ddd;
			padding: 1em 0 1em 0;
		}

			ul.divided li:first-child {
				border-top: 0;
				padding-top: 0;
			}

/* Social */

	ul.social {
		list-style: none;
		padding-left: 0;
		overflow: hidden;
		cursor: default;
		margin: 0 0 3.25em 0;
	}

		ul.social li {
			display: inline-block;
			padding-left: 0;
			margin-left: 0.75em;
		}

			ul.social li:first-child {
				margin-left: 0;
			}

			ul.social li a {
				display: inline-block;
				width: 3em;
				height: 3em;
				text-align: center;
				border-radius: 5px;
				background: #888;
				-moz-transition: background-color .25s ease-in-out;
				-webkit-transition: background-color .25s ease-in-out;
				-ms-transition: background-color .25s ease-in-out;
				transition: background-color .25s ease-in-out;
			}

				ul.social li a:before {
					color: #fff;
					font-size: 2em;
					line-height: 1.5em;
				}

				ul.social li a.fa-facebook {
					background: #3c5a98;
				}

					ul.social li a.fa-facebook:hover {
						background: #4c6aa8;
					}

				ul.social li a.fa-twitter {
					background: #2daae4;
				}

					ul.social li a.fa-twitter:hover {
						background: #3dbaf4;
					}

				ul.social li a.fa-dribbble {
					background: #c4376b;
				}

					ul.social li a.fa-dribbble:hover {
						background: #d4477b;
					}

				ul.social li a.fa-linkedin {
					background: #006599;
				}

					ul.social li a.fa-linkedin:hover {
						background: #1075a9;
					}

				ul.social li a.fa-tumblr {
					background: #2b4661;
				}

					ul.social li a.fa-tumblr:hover {
						background: #3b5671;
					}

				ul.social li a.fa-google-plus {
					background: #da2713;
				}

					ul.social li a.fa-google-plus:hover {
						background: #ea3723;
					}

/* Icons */

	.icon {
		text-decoration: none;
		position: relative;
		text-decoration: none;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			text-transform: none !important;
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
		}

		.icon.solid:before {
			font-weight: 900;
		}

		.icon.brands:before {
			font-family: 'Font Awesome 5 Brands';
		}

		.icon:before {
			line-height: inherit;
		}

		.icon > .label {
			display: none;
		}

		.icon.featured {
			position: relative;
			display: inline-block;
			background-color: #d52349;
			width: 9em;
			padding: 1.75em 0 0.75em 0;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			margin: 0 0 4.5em 0;
			cursor: default;
		}

			.icon.featured:before {
				font-size: 4.5em;
				line-height: 1em;
				color: #fff;
			}

			.icon.featured:after {
				content: '';
				position: absolute;
				bottom: -1.95em;
				left: 0;
				border-top: solid 2em #d52349;
				border-left: solid 4.5em transparent;
				border-right: solid 4.5em transparent;
			}

			.icon.featured.alt {
				background-color: #252122;
			}

				.icon.featured.alt:after {
					border-top-color: #252122;
				}

			.icon.featured.alt2 {
				background-color: #827a7c;
			}

				.icon.featured.alt2:after {
					border-top-color: #827a7c;
				}

/* Page Wrapper */

	#page-wrapper > section {
		margin-bottom: 0;
	}

/* Header */

	#header {
		position: relative;
		background: #ffffff;
		border-bottom: solid 1px #ccc;
		padding: 5em 0 4em 0;
		text-align: center;
	}

		#header h1 {
			color: #252122;
			font-weight: 900;
			font-size: 2.5em;
			letter-spacing: -0.035em;
			line-height: 1;
		}

/* Nav */

	#nav {
		margin: 2.5em 0 0 0;
	}

		#nav > ul {
			margin: 0;
		}

			#nav > ul > li {
				display: inline-block;
				font-style: italic;
				margin: 0 0.35em 0 0.35em;
			}

				#nav > ul > li > ul {
					display: none;
				}

				#nav > ul > li > a {
					border-radius: 5px;
					color: #5d5d5d;
					text-decoration: none;
					padding: 0.6em 1.2em 0.6em 1.2em;
					-moz-transition: background-color .25s ease-in-out;
					-webkit-transition: background-color .25s ease-in-out;
					-ms-transition: background-color .25s ease-in-out;
					transition: background-color .25s ease-in-out;
					outline: 0;
				}

				#nav > ul > li:hover > a, #nav > ul > li.active > a {
					background: #f3f3f3;
				}

				#nav > ul > li.current > a {
					background: #d52349;
					color: #fff !important;
					font-weight: 700;
				}
				a.current {
					background: #d52349;
					color: #fff !important;
					font-weight: 700;
				}

	.dropotron {
		border-radius: 5px;
		background-color: #252122;
		background-color: rgba(34, 30, 31, 0.98);
		padding: 1.25em 1.5em 1.25em 1.5em;
		font-style: italic;
		min-width: 13em;
		box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.5);
		text-align: left;
		margin-top: -1.25em;
		margin-left: -1px;
		list-style: none;
	}

		.dropotron a, .dropotron span {
			color: #aaa;
			text-decoration: none;
			-moz-transition: color .25s ease-in-out;
			-webkit-transition: color .25s ease-in-out;
			-ms-transition: color .25s ease-in-out;
			transition: color .25s ease-in-out;
		}

		.dropotron li {
			padding: 0.25em 0 0.25em 0;
		}

			.dropotron li:hover > a, .dropotron li:hover > span {
				color: #fff;
			}

		.dropotron.level-0 {
			margin-top: 2em;
		}

			.dropotron.level-0:before {
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				margin-left: -10px;
				top: -9px;
				border-left: solid 10px transparent;
				border-right: solid 10px transparent;
				border-bottom: solid 10px #252122;
				border-bottom-color: rgba(34, 30, 31, 0.98);
			}

/* Banner */

	#banner {
		background: #CCE8F4 url("/images/pic01.jpg");
		background-position: center center;
		background-size: auto 100%;
		background-repeat: no-repeat;
		text-align: center;
		position: relative;
		padding: 8em 0;
		margin: 4em 0 0 0;
	}

		#banner header {
			background-color: rgba(34, 30, 31, 0.98);
			display: inline-block;
			padding: 2.5em 5em;
			border-radius: 5px;
		}

			#banner header h2 {
				color: #fff;
				font-weight: 700;
				font-size: 2.5em;
				margin: 0 0 0.65em 0;
			}

			#banner header p {
				color: #d52349;
				padding: 0;
				font-style: normal;
				margin: 0;
				font-size: 1.5em;
			}

/* Intro */

	#intro {
		text-align: center;
		overflow: hidden;
	}

		#intro section {
			margin: 3em 0;
			padding: 2.5em 0;
		}

		#intro h2 {
			font-size: 1.75em;
		}

		#intro p {
			margin: 0;
		}

		#intro .middle {
			position: relative;
			z-index: 1;
		}

			#intro .middle:before {
				content: '';
				width: 32px;
				height: 100%;
				position: absolute;
				left: -24px;
				top: 0;
				display: block;
				z-index: -1;
				box-shadow: 32px 0 0 0 #fff, 0 -32px 0 0 #fff, 0 32px 0 0 #fff, 32px 32px 0 0 #fff, 32px -32px 0 0 #fff, 0 0 32px 0 rgba(0, 0, 0, 0.15);
			}

			#intro .middle:after {
				content: '';
				width: 32px;
				height: 100%;
				position: absolute;
				right: -24px;
				top: 0;
				display: block;
				z-index: -1;
				box-shadow: -32px 0 0 0 #fff, 0 -32px 0 0 #fff, 0 32px 0 0 #fff, -32px 32px 0 0 #fff, -32px -32px 0 0 #fff, 0 0 32px 0 rgba(0, 0, 0, 0.15);
			}

		#intro .button {
			min-width: 12em;
		}

		#intro footer {
			margin: 0;
		}

/* Main */

	#main {
		position: relative;
		background: #f7f7f7 url("images/bg.png");
		padding: 4em 0 4em 0;
	}

		#main:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			background: url("images/bg.png");
			background-size: 100% 100%;
			z-index: 0;
			opacity: 0.1;
		}

		#main .major h2 {
			background: #f7f7f7 url("images/bg.png");
		}

/* Footer */

	#footer {
		position: relative;
		color: #858484;
		padding: 4em 0;
	}

		#footer:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;

			background: url("images/bg.png");
			background-size: 100% 100%;
			z-index: 0;
			opacity: 0.5;
		}

		#footer a {
			color: #c5c4c4;
		}

		#footer h2, #footer h3, #footer h4, #footer h5, #footer h6, #footer strong, #footer b {
			color: #fff;
		}

			#footer h2 a, #footer h3 a, #footer h4 a, #footer h5 a, #footer h6 a, #footer strong a, #footer b a {
				color: inherit;
			}

		#footer ul.contact li,
		#footer ul.dates li,
		#footer ul.divided li {
			border-top-color: #353434;
			border-top-color: rgba(255, 255, 255, 0.05);
		}

		#footer header {
			margin: 0 0 3em 0;
		}

			#footer header h2 {
				font-size: 1.75em;
			}

/* Copyright */

	#copyright {
		text-align: center;
		margin: 3em 0 2em 0;
	}

		#copyright ul.links {
			display: inline-block;
			background: #2C2929;
			background: rgba(255, 255, 255, 0.05);
			border-radius: 5px;
			margin: 0;
			padding: 0.85em 2.25em 0.85em 2.25em;
		}

/* XLarge */

	@media screen and (max-width: 1680px) {

		/* Basic */

			body, input, textarea, select {
				font-size: 12pt;
			}

	}

/* Large */

	@media screen and (max-width: 1280px) {

		/* Basic */

			body, input, textarea, select {
				font-size: 11pt;
			}

		/* Section/Article */

			header.major {
				margin: 0.75em 0 2.5em 0;
			}

				header.major h2 {
					padding: 0 1em 0 1em;
				}

		/* Social */

			ul.social li {
				margin: 0 0 0.25em 0.75em;
			}

		/* Header */

			#header {
				padding: 4em 0 3em 0;
			}

		/* Banner */

			#banner {
				padding: 6em 0;
				margin: 3em 0 0 0;
			}

		/* Intro */

			#intro {
				padding: 0;
			}

				#intro section {
					padding: 1em 2em;
				}

				#intro .middle:before {
					left: -12px;
				}

				#intro .middle:after {
					right: -12px;
				}

		/* Main */

			#main {
				padding: 30px 0;
			}

		/* Footer */

			#footer {
				padding: 3em 0;
			}

				#footer:before {
					height: 2.5em;
				}

				#footer header {
					margin: 0 0 1.5em 0;
				}

					#footer header h2 {
						font-size: 1.25em;
					}

		/* Copyright */

			#copyright {
				margin: 0;
			}

	}

/* Medium */

	#navPanel, #titleBar {
		display: none;
	}

	@media screen and (max-width: 980px) {

		/* Basic */

			html, body {
				overflow-x: hidden;
			}

		/* Box */

			.box footer ul.actions li {
				margin: 1em 0 0 0;
				display: block;
			}

				.box footer ul.actions li .button {
					width: 100%;
				}

				.box footer ul.actions li:first-child {
					margin-top: 0;
				}

		/* Header */

			#header {
				padding: 3.5em 0 3em 0;
			}

		/* Nav */

			#nav {
				display: none;
			}

		/* Intro */

			#intro {
				padding: 3em 0 0 0;
			}

				#intro section {
					position: relative;
					border-top: solid 1px #dfdfdf;
					margin: 0;
					padding: 40px 0 0 0;
				}

					#intro section.first {
						border-top: 0;
						padding-top: 0;
					}

					#intro section p {
						margin: 0;
					}

				#intro .middle:before, #intro .middle:after {
					display: none;
				}

				#intro footer {
					padding: 3em 0 0 0;
				}

					#intro footer ul.actions {
						margin-bottom: 0;
					}

		/* Main */

			#main {
				padding: 40px 0;
			}

		/* Copyright */

			#copyright ul.links {
				display: block;
			}

		/* Nav */

			#page-wrapper {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				padding-bottom: 1px;
			}

			#titleBar {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 44px;
				left: 0;
				position: fixed;
				top: 0;
				width: 100%;
				z-index: 10001;
				background: none;
			}

				#titleBar .toggle {
					text-decoration: none;
					width: 80px;
					height: 60px;
				}

					#titleBar .toggle:before {
						-moz-osx-font-smoothing: grayscale;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						text-transform: none !important;
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
					}

					#titleBar .toggle:before {
						content: '\f0c9';
						display: block;
						position: absolute;
						left: 10px;
						top: 10px;
						width: 50px;
						height: 40px;
						line-height: 40px;
						font-size: 18px;
						color: #fff;
						text-align: center;
						background: rgba(150, 150, 150, 0.75);
						border-radius: 5px;
					}

					#titleBar .toggle:active:before {
						background-color: #969696;
					}

			#navPanel {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transform: translateX(-275px);
				-webkit-transform: translateX(-275px);
				-ms-transform: translateX(-275px);
				transform: translateX(-275px);
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 100%;
				left: 0;
				overflow-y: auto;
				position: fixed;
				top: 0;
				width: 275px;
				z-index: 10002;
				color: #858484;
				background-image: url("images/bg.png");
				box-shadow: inset -3px 0px 5px 0px rgba(0, 0, 0, 0.35);
			}

				#navPanel .link {
					display: block;
					color: #888;
					text-decoration: none;
					height: 44px;
					line-height: 44px;
					border-top: solid 1px rgba(255, 255, 255, 0.05);
					padding: 0 1em 0 1em;
					font-style: italic;
				}

					#navPanel .link:first-child {
						border-top: 0;
					}

				#navPanel .indent-1 {
					display: inline-block;
					width: 1em;
				}

				#navPanel .indent-2 {
					display: inline-block;
					width: 2em;
				}

				#navPanel .indent-3 {
					display: inline-block;
					width: 3em;
				}

				#navPanel .indent-4 {
					display: inline-block;
					width: 4em;
				}

				#navPanel .indent-5 {
					display: inline-block;
					width: 5em;
				}

				#navPanel .depth-0 {
					color: #fff;
				}

			body.navPanel-visible #page-wrapper {
				-moz-transform: translateX(275px);
				-webkit-transform: translateX(275px);
				-ms-transform: translateX(275px);
				transform: translateX(275px);
			}

			body.navPanel-visible #titleBar {
				-moz-transform: translateX(275px);
				-webkit-transform: translateX(275px);
				-ms-transform: translateX(275px);
				transform: translateX(275px);
			}

			body.navPanel-visible #navPanel {
				-moz-transform: translateX(0);
				-webkit-transform: translateX(0);
				-ms-transform: translateX(0);
				transform: translateX(0);
			}

	}

/* Small */

	@media screen and (max-width: 736px) {

		/* Basic */

			body, input, textarea, select {
				font-size: 11pt;
			}

			h2, h3, h4, h5, h6 {
				font-size: 1.25em;
			}

		/* Button */

			input[type="button"].large,
			input[type="submit"].large,
			input[type="reset"].large,
			button.large,
			.button.large {
				font-size: 1.1em;
				padding: 0.65em 1.5em 0.65em 1.5em;
			}

		/* Actions */

			ul.actions li {
				margin: 1em 0 0 0;
				display: block;
			}

				ul.actions li .button {
					width: 100%;
				}

				ul.actions li:first-child {
					margin-top: 0;
				}

		/* Section/Article */

			section, article {
				margin-bottom: 20px;
			}

			header {
				margin: 0 0 1em 0;
			}

			footer {
				margin: 2em 0 0 0;
			}

		/* Box */

			.box {
				padding: 25px 15px 20px 15px;
			}

				.box .image.featured {
					left: 15px;
					top: 15px;
					margin: -40px 0 40px -30px;
				}

				.box.post header {
					padding-top: 0;
					margin: 0 0 1em 0;
				}

				.box.post h2 {
					font-size: 1.5em;
					line-height: 1.5em;
				}

				.box.post header > p {
					margin-top: 0;
					font-size: 1em;
				}

		/* Divided */

			ul.divided li {
				padding: 0.5em 0 0.5em 0;
			}

		/* Contact */

			ul.contact li {
				padding: 0.8em 0 0.8em 7em;
			}

				ul.contact li h3 {
					top: 0.8em;
				}

		/* Dates */

			ul.dates li {
				padding-left: 6em;
			}

			ul.dates h3 {
				margin: 0 0 0.5em 0;
			}

		/* Links */

			ul.links li {
				display: block;
				padding: 0.25em 0 0 0;
				margin: 0.25em 0 0 0;
				border-left: 0;
			}

				ul.links li:first-child {
					padding-top: 0;
					margin-top: 0;
				}

		/* Social */

			ul.social li {
				margin: 0 0.5em 0.75em 0;
			}

		/* Icons */

			.icon.featured {
				width: 8em;
				margin: 0 0 3em 0;
			}

				.icon.featured:after {
					border-left: solid 4em transparent;
					border-right: solid 4em transparent;
				}

		/* Header */

			#header {
				text-align: center;
				padding: 2em 0;
			}

				#header h1 {
					color: #252122;
					font-weight: 900;
					font-size: 1.75em;
					letter-spacing: -0.035em;
				}

		/* Intro */

			#intro {
				padding: 2em 0 0 0;
			}

				#intro section {
					padding: 40px 0 0 0;
					margin: 20px 0 0 0;
				}

				#intro h2 {
					font-size: 1.25em;
				}

		/* Banner */

			#banner {
				padding: 6em 0;
				margin: 2em 0 0 0;
			}

				#banner header {
					padding: 1.5em 1em 1.5em 1em;
					display: block;
					border-radius: 0;
				}

					#banner header h2 {
						font-size: 1.5em;
						line-height: 1.5em;
						margin: 0 0 0.25em 0;
					}

					#banner header p {
						font-size: 1.2em;
					}

		/* Main */

			#main {
				padding: 20px 0;
			}

		/* Footer */

			#footer {
				padding: 40px 0 20px 0;
			}

	}

/* Form */

	form {
		margin: 0 0 2em 0;
	}

		form > :last-child {
			margin-bottom: 0;
		}

		form > .fields {
			display: -moz-flex;
			display: -webkit-flex;
			display: -ms-flex;
			display: flex;
			-moz-flex-wrap: wrap;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			width: calc(100% + 3em);
			margin: -1.5em 0 2em -1.5em;
		}

			form > .fields > .field {
				-moz-flex-grow: 0;
				-webkit-flex-grow: 0;
				-ms-flex-grow: 0;
				flex-grow: 0;
				-moz-flex-shrink: 0;
				-webkit-flex-shrink: 0;
				-ms-flex-shrink: 0;
				flex-shrink: 0;
				padding: 1.5em 0 0 1.5em;
				width: calc(100% - 1.5em);
			}

				form > .fields > .field.half {
					width: calc(50% - 0.75em);
				}

				form > .fields > .field.third {
					width: calc(100%/3 - 0.5em);
				}

				form > .fields > .field.quarter {
					width: calc(25% - 0.375em);
				}

		@media screen and (max-width: 480px) {

			form > .fields {
				width: calc(100% + 3em);
				margin: -1.5em 0 2em -1.5em;
			}

				form > .fields > .field {
					padding: 1.5em 0 0 1.5em;
					width: calc(100% - 1.5em);
				}

					form > .fields > .field.half {
						width: calc(100% - 1.5em);
					}

					form > .fields > .field.third {
						width: calc(100% - 1.5em);
					}

					form > .fields > .field.quarter {
						width: calc(100% - 1.5em);
					}

		}

	label {
		color: #ffffff;
		display: block;
		font-family: Raleway, Helvetica, sans-serif;
		font-size: 0.8em;
		font-weight: 700;
		letter-spacing: 0.1em;
		margin: 0 0 0.7em 0;
		text-transform: uppercase;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		background: rgba(255, 255, 255, 0.025);
		border-radius: 5px;
		border: none;
		border: solid 2px rgba(255, 255, 255, 0.125);
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;
	}

		input[type="text"]:invalid,
		input[type="password"]:invalid,
		input[type="email"]:invalid,
		input[type="tel"]:invalid,
		select:invalid,
		textarea:invalid {
			box-shadow: none;
		}

		input[type="text"]:focus,
		input[type="password"]:focus,
		input[type="email"]:focus,
		input[type="tel"]:focus,
		select:focus,
		textarea:focus {
			border-color: #5b6ba6;
		}

	select {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.125)' /%3E%3C/svg%3E");
		background-size: 1.25rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 1rem) center;
		height: 2.75em;
		padding-right: 2.75em;
		text-overflow: ellipsis;
	}

		select option {
			color: #ffffff;
			background: #2e3141;
		}

		select:focus::-ms-value {
			background-color: transparent;
		}

		select::-ms-expand {
			display: none;
		}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: 2.75em;
	}

	textarea {
		padding: 0.75em 1em;
	}

